import React from "react";
import { Link } from "gatsby";
import { Layout, SEO } from '../../components/structure';
import { Content, Hero } from "../../components/blocks"

const LegalPage = () => (
  <Layout>
    <SEO title="Legal" description={'Legal information and resources for Points products and services'} />
    <Hero title={'Legal'} />
    <Content>
      <p>Find legal information and resources for Points products and services.</p>
      <ul>
        <li><Link to={"/legal/terms"}>Terms of Use</Link></li>
        <li><Link to={"/legal/privacy"}>Privacy Policy</Link></li>
        <li><Link to={"/legal/accessibility"}>Accessibility</Link></li>
        <li><Link to={"/legal/gdpr"}>GDPR</Link></li>
      </ul>
    </Content>
  </Layout>
);

export default LegalPage;
